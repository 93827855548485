<template>
    <div class="up_content_inner up_manage_site">
        <div class="up_main_container">
            <div class="up_page_header p-mb-4">
                <h2 class="up_page_title p-mr-6">Invoices Detail for</h2>
                <Button type="button" :icon="['pi', allInvoiceDetails.loadingDownload ? 'pi-spin pi-spinner' : 'pi-download']" label="Download" class="p-button-primary p-mr-2" @click="downloadInvoice()" />
            </div>
            <div class="up_inner_container">
                <!--- Show Invoice details Table Start --->
                <DataTable class="p-datatable p-mb-2" :value="allInvoiceDetails.data" :rowHover="true" :loading="allInvoiceDetails.loading" responsiveLayout="scroll" removableSort>
                    <template #loading>
                        Loading Invoive data. Please wait.
                    </template>
                    <template #empty>
                        No data available in table.
                    </template>
                    <Column field="invoiceNumber" header="Invoice #" style="text-align: center" />
                    <Column field="siteUrl" header="Website" style="text-align: center">
                        <template #body="slotProps">
                            <div class="p-d-flex">
                                <div class="up_website_info">
                                    <span class="sup_site_info p-d-block">{{slotProps.data.Website.siteName}}</span>
                                    <a :href="'https://' + [slotProps.data.Website.siteUrl]" class="sup_website_url" target="_blank">{{slotProps.data.Website.siteUrl}}</a>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="service" header="Service For" style="text-align: center">
                        <template #body="slotProps">
                            {{slotProps.data.Website.serviceFor === 'both' ? 'ADA/Cookie Consent/CCPA Bundle'
                            : slotProps.data.Website.serviceFor.toUpperCase()}}
                        </template>
                    </Column>
                    <Column field="orderType" header="Order Type" style="text-align: center" />
                    <Column field="billingPeriod" header="Billing Period" style="text-align: center">
                        <template #body="slotProps">
                            {{usFormatDate((slotProps.data.billingPeriod).split(" ")[0]) + '  ' + usFormatDate((slotProps.data.billingPeriod).split(" ")[1])}}
                        </template>
                    </Column>
                </DataTable>
                <!--- Show Invoice details Table End --->
                <!--- Show Order Total Start --->
                <div class="up_payment_total">
                    <h3>Order Total</h3>
                    <table>
                        <tbody>
                            <tr v-if="allInvoiceDetails.stripePaymentId === 'na'">
                                <td>Payment Type</td>
                                <td>
                                    <span class="up_site_free_status">
                                        <span :class="['up_item_free_status', allInvoiceDetails.stripePaymentId === 'na' ? 'up_item_free_status_success' : '' ]">{{allInvoiceDetails.stripePaymentId === 'na' ? 'Free' : ''}}</span>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Total Unique Visitor</td>
                                <td><strong>{{allInvoiceDetails.visitor}}</strong></td>
                            </tr>
                            <tr>
                                <td>Tier Applied</td>
                                <td>{{allInvoiceDetails.tire}}</td>
                            </tr>
                            <tr>
                                <td>Rate</td>
                                <td>{{'$' + allInvoiceDetails.rate}}</td>
                            </tr>
                            <tr v-if="allInvoiceDetails.discount !== null" class="discount-row">
                                <td>
                                    <span class="discount-box">
                                        <i class="pi pi-check-circle"></i>
                                        <span>
                                            Code {{allInvoiceDetails.discountCode}} Applied
                                            <small>{{allInvoiceDetails.discountType === "fixed" ? '$' : ''}}{{allInvoiceDetails.discount}}{{allInvoiceDetails.discountType === "percentage" ? '% off on the rate.' : 'off on the rate.'}}</small>
                                        </span>
                                    </span>
                                </td>
                                <td>- {{allInvoiceDetails.discountAmount}}</td>
                            </tr>
                        </tbody>
                        <tfoot class="total">
                            <tr>
                                <th>Total</th>
                                <td>{{'$' + allInvoiceDetails.charged}}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <!--- Show Order Total End --->
            </div>
            <div v-if="allInvoiceDetails.card.length > 0" class="up_payment_method">
                <h4 class="p-mb-3">Payment Methods</h4>
                <div class="up_payment_card_wrap new_cardtype_list">
                    <!-- <div class="up_payment_card" v-for="card of allInvoiceDetails.card" :key="card">
                        <div class="up_card_details">
                            <img :src="imageUrlFrontEnd + 'images/' + [card.cardBrand === 'visa' ? 'visa.jpg' : card.cardBrand === 'MasterCard' ? 'master_card.png' : 'amex.png']" width="40" height="25">
                            MasterCard - xxxx {{card.cardLast4}} Expiry - 12/2025
                        </div>
                    </div> -->
                    <div class="new_single_card" v-for="card of allInvoiceDetails.card" :key="card">
                        <div :class="['card_inner_details', card.isPrimary ? 'up_card_selected' : '', card.isExpired ? 'up_card_expired' : '', card.brand === 'Visa' ? 'visa-bg-wrapper' : card.brand === 'MasterCard' ? 'master-card-wrapper' : 'amex-wrapper' ]" :data-name="card.isPrimary ? 'Primary' : ''">
                            <div class="add_card_type">
                                <div class="left">
                                    <div class="card-chip">
                                        <span>{{card.isPrimary}}</span>
                                        <!-- <img :src="imageUrlFrontEnd + 'images/chip-card.png' " width="60" height="35"> -->
                                        <img src="https://truabilities-dev.s3.us-west-1.amazonaws.com/Media/Users/chip-card.png?AWSAccessKeyId=AKIAZJ4VKLIYQDJKT4WU&Expires=1966641878&Signature=HbSgd%2BZmCtxzMWHh3i7OahILs74%3D" >
                                    </div>
                                </div>
                                <div class="right">Credit Card</div>
                            </div>
                            <div class="card-number-new">
                                <span>**** **** ****</span> {{card.cardLast4}}
                            </div>
                            <div class="card-with-auth-name">
                                <div class="card-validity">
                                    <span class="validity-data">
                                       <span class="valid-heading">Valid</span> 03/25
                                    </span>
                                    <div class="card-type-logo">
                                        <img :src="imageUrlFrontEnd + 'images/' + [card.brand === 'Visa' ? 'visa.jpg' : card.brand === 'MasterCard' ? 'master_card.png' : 'amex.png']" width="60" height="35">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userPortalInvoiceDownload } from '../../../../config/appUrls';
import { apiKey, imageUrlFrontEnd } from '../../../../config/constant';
import { reactive, onBeforeMount } from 'vue';
import { useRoute } from "vue-router";
import router from "../../../../router/index";
import axios from "axios";
import { useStore } from 'vuex';
import { usFormatDate } from '../../../../helper/utility/utility';
const FileSaver = require('file-saver');

export default {
    name: "invoiceDetails",
    setup(){
        //Application store call
        const store = useStore();

        //Route call
        const route = useRoute();

        //Invoice details initial data storing state
        const allInvoiceDetails = reactive({
            data: null,
            loading: false,
            loadingDownload: false,
            card: [],
            visitor: null,
            tire: null,
            rate: null,
            charged: null,
            discountCode: null,
            discountType: null,
            discount: null,
            discountAmount: null,
            total: null,
            stripePaymentId: null
        });

        onBeforeMount(async() => {
            //Validating if only props has value
            if (Object.keys(route.params).length > 0) {
                let data = JSON.parse(route.params.invoiceData);
                updateInvoiceDetails(data);
            } else {
                router.push({name: "CustomerInvoiceList"});
            }
        });

        /**
         * 
         * Updating local states with props
         * 
         */
        const updateInvoiceDetails = (data) =>{
            allInvoiceDetails.loading = !allInvoiceDetails.loading;
            allInvoiceDetails.data = [data];
            allInvoiceDetails.card.push(data.Stripetransaction);
            allInvoiceDetails.visitor = data.chargedHits;
            allInvoiceDetails.tire = data.chargedPackage;
            allInvoiceDetails.rate = data.chargedPackageRate;
            allInvoiceDetails.charged = data.chargedAmount;
            allInvoiceDetails.discountCode = data.Payment.couponCode !==null ? data.Payment.couponCode : null;
            allInvoiceDetails.discountType = data.Payment.couponType;
            allInvoiceDetails.discount = data.Payment.couponDiscount;
            allInvoiceDetails.discountAmount = data.discountAmount;
            allInvoiceDetails.total = data.chargedAmount;
            allInvoiceDetails.loading = !allInvoiceDetails.loading;
            allInvoiceDetails.stripePaymentId = data.Stripetransaction.stripePaymentId;
        }

        /**
         * 
         * Invoice PDF downloading api with arraybuffer call
         * 
         */
        const downloadInvoice = async() => {
            allInvoiceDetails.loadingDownload = !allInvoiceDetails.loadingDownload;
            const response = await axios.get( userPortalInvoiceDownload, {
                responseType: 'arraybuffer',
                params: {
                    uuid : allInvoiceDetails.data[0].uuid
                },
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                }, 
            });
            if(response.status === 200) {
                const dirtyFileName = response.headers['content-disposition'];
                const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                var fileName = dirtyFileName.match(regex)[3];
                var blob = new Blob([response.data], { type: 'application/pdf' });
                FileSaver(blob, fileName);
                allInvoiceDetails.loadingDownload = !allInvoiceDetails.loadingDownload;
            }
        }

        return{
            allInvoiceDetails,
            imageUrlFrontEnd,
            usFormatDate,
            downloadInvoice
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/style/component/userportal/pages/invoice/Invoice';
</style>